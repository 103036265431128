import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@makeably/creativex-design-system';

export const NULL_VALUE = '~';

export const optionProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
});

export const filterProps = PropTypes.objectOf(
  PropTypes.arrayOf(optionProps),
);

export const emptyState = (
  <div className="t-empty u-flexRow u-justifyCenter">
    No data to display
  </div>
);

export function getNullableDimension(value) {
  if (!value) {
    return {
      label: 'N/A',
      value: NULL_VALUE,
    };
  }

  return { value };
}

export function renderDeleteButton(onClick) {
  return (
    <Button
      label="Delete"
      variant="destructive"
      onClick={onClick}
    />
  );
}
