import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Card,
  MessageModal,
  Search,
} from '@makeably/creativex-design-system';
import {
  NULL_VALUE,
  emptyState,
  filterProps,
  getNullableDimension,
  renderDeleteButton,
} from 'components/internal/shared';
import FilterTags from 'components/molecules/FilterTags';
import ItemsFilter from 'components/molecules/ItemsFilter';
import ItemsTable from 'components/molecules/ItemsTable';
import { addToast } from 'components/organisms/Toasts';
import { saveFilterQuery } from 'utilities/filtering';
import { getItemSortBy } from 'utilities/item';
import { setItemsElement } from 'utilities/itemElement';
import {
  filterItems,
  getValueOptions,
} from 'utilities/itemFilter';
import { searchItems } from 'utilities/itemSearch';
import { destroy } from 'utilities/requests';
import {
  newInternalReviewGuidePath,
  editInternalReviewGuidePath,
  internalReviewGuidePath,
  internalReviewGuideSubscriptionsPath,
} from 'utilities/routes';
import { toDate } from 'utilities/string';
import {
  getPage,
  getParams,
  setParam,
} from 'utilities/url';
import styles from './ReviewGuides.module.css';

const guideSubscriptionProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  company: PropTypes.string,
  definitionId: PropTypes.number,
  definitionName: PropTypes.string,
  guidelineId: PropTypes.number,
  guidelineName: PropTypes.string,
});

const propTypes = {
  guideSubscriptions: PropTypes.arrayOf(guideSubscriptionProps).isRequired,
  initialFilterSelections: filterProps,
};

const defaultProps = {
  initialFilterSelections: {},
};

const filterDimensions = [
  {
    label: 'Company',
    value: 'company',
  },
  {
    label: 'Definition Name',
    value: 'definitionName',
  },
  {
    label: 'Guideline Name',
    value: 'guidelineName',
  },
];

const headers = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'title',
    label: 'Title',
  },
  {
    key: 'updatedAt',
    label: 'Last Updated',
  },
  {
    key: 'companyCount',
    label: 'Companies',
  },
  {
    key: 'guidelineCount',
    label: 'Guidelines',
  },
  {
    key: 'definitionCount',
    label: 'Definitions',
  },
  {
    key: 'remove',
    label: '',
    sortable: false,
  },
];

function getItems(guideSubscriptions) {
  return guideSubscriptions.map(({
    company,
    definitionId,
    definitionName,
    guidelineId,
    guidelineName,
    id,
    updatedAt,
    title,
  }) => ({
    company: getNullableDimension(company),
    definitionId: getNullableDimension(definitionId),
    definitionName: getNullableDimension(definitionName),
    guidelineId: getNullableDimension(guidelineId),
    guidelineName: getNullableDimension(guidelineName),
    id: {
      element: <a href={editInternalReviewGuidePath(id)}>{ id }</a>,
      value: id,
    },
    updatedAt: {
      label: toDate(new Date(updatedAt)),
      value: updatedAt,
    },
    title: { value: title },
  }));
}

function getCounts(binItems) {
  const id = binItems[0].id.value;
  const data = binItems.reduce(({
    companySet,
    definitionCount,
    guidelineCount,
  }, {
    company,
    definitionId,
    guidelineId,
  }) => {
    const isDefinition = definitionId.value !== NULL_VALUE;
    const isGuideline = !isDefinition && guidelineId.value !== NULL_VALUE;

    return {
      companySet: companySet.add(company.value),
      definitionCount: definitionCount + (isDefinition ? 1 : 0),
      guidelineCount: guidelineCount + (isGuideline ? 1 : 0),
    };
  }, {
    companySet: new Set([NULL_VALUE]),
    definitionCount: 0,
    guidelineCount: 0,
  });

  return {
    companyCount: { value: data.companySet.size - 1 },
    definitionCount: {
      element: <a href={internalReviewGuideSubscriptionsPath(id)}>{ data.definitionCount }</a>,
      value: data.definitionCount,
    },
    guidelineCount: {
      element: <a href={internalReviewGuideSubscriptionsPath(id)}>{ data.guidelineCount }</a>,
      value: data.guidelineCount,
    },
  };
}

function groupIntoBins(items, handleDelete) {
  const binsByKey = Object.groupBy(items, ({ id }) => id.value);

  return Object.values(binsByKey).map((binItems) => {
    const {
      companyCount,
      definitionCount,
      guidelineCount,
    } = getCounts(binItems);
    const {
      id,
      title,
      updatedAt,
    } = binItems[0];
    const onDelete = () => handleDelete(id.value, guidelineCount.value, definitionCount.value);

    return {
      companyCount,
      definitionCount,
      guidelineCount,
      id,
      updatedAt,
      remove: {
        element: renderDeleteButton(onDelete),
        value: id.value,
      },
      title,
    };
  });
}

function ReviewGuides({ guideSubscriptions, initialFilterSelections }) {
  const params = getParams(window);
  const [items, setItems] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [filterSelections, setFilterSelections] = useState(initialFilterSelections);
  const [bins, setBins] = useState(items);
  const [search, setSearch] = useState('');
  const [searchedBins, setSearchedBins] = useState(items);
  const [sort, setSort] = useState({
    key: 'updatedAt',
    asc: false,
  });
  const [sortedBins, setSortedBins] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [page, setPage] = useState(getPage(params));

  const confirmDelete = async (id) => {
    const { isError } = await destroy(internalReviewGuidePath(id));

    if (!isError) {
      addToast('Review guide deleted');
    }

    window.location.reload();
  };

  const handleDelete = (id, guidelineCount, definitionCount) => {
    if (guidelineCount === 0 && definitionCount === 0) {
      confirmDelete(id);
    } else {
      setDeleteMessage(
        `This review guide has subscriptions:\n${
          guidelineCount ? `- ${guidelineCount} guideline subscription(s)\n` : ''}${
          definitionCount ? `- ${definitionCount} definition subscription(s)\n` : ''
        }\nAre you sure you want to delete it?`,
      );
      setDeleteId(id);
    }
  };

  useEffect(() => {
    const allItems = getItems(guideSubscriptions);
    const options = getValueOptions(filterDimensions, allItems);

    setItems(allItems);
    setFilterOptions(options);
  }, [guideSubscriptions]);

  useEffect(() => {
    const filteredItems = filterItems(items, filterSelections);
    setBins(groupIntoBins(filteredItems, handleDelete));
  }, [items, filterSelections]);

  useEffect(() => {
    const found = searchItems(bins, search, ['title']);
    setSearchedBins(setItemsElement(found));
  }, [bins, search]);

  useEffect(() => {
    const byKeyDir = getItemSortBy(sort.key, sort.asc);
    setSortedBins(searchedBins.slice().sort(byKeyDir));
  }, [searchedBins, sort]);

  const handleFilterSelect = async (value) => {
    setPage(1);
    setFilterSelections(value);

    if (Object.keys(value).length > 0) {
      const uuid = await saveFilterQuery(value);
      setParam('filter_uuid', uuid, params, window);
    } else {
      setParam('filter_uuid', '', params, window);
    }
  };

  return (
    <>
      <Card className={styles.main}>
        <div className={styles.add}>
          <AddNewButton
            label="Add Review Guide"
            url={newInternalReviewGuidePath()}
          />
        </div>
        <div className={styles.controls}>
          <Search
            placeholder="Search by Title"
            value={search}
            onChange={setSearch}
          />
          <div className={styles.filter}>
            <ItemsFilter
              dimensions={filterDimensions}
              isOpen={filterOpen}
              options={filterOptions}
              selections={filterSelections}
              onClose={() => setFilterOpen(false)}
              onOpen={() => setFilterOpen(true)}
              onSelect={handleFilterSelect}
            />
            <FilterTags
              dimensions={filterDimensions}
              selections={filterSelections}
              onClick={() => setFilterOpen(true)}
              onRemove={handleFilterSelect}
            />
          </div>
        </div>
        <ItemsTable
          className="x-scroll-shadows"
          emptyTableContent={emptyState}
          headers={headers}
          items={sortedBins}
          page={page}
          sort={sort}
          onPageChange={setPage}
          onSortChange={setSort}
        />
      </Card>
      <MessageModal
        actionButtonLabel="Delete"
        actionButtonVariant="destructive"
        isOpen={Boolean(deleteId)}
        title="Delete Review Guide With Subscriptions"
        onActionButtonClick={() => confirmDelete(deleteId)}
        onClose={() => setDeleteId(null)}
      >
        <div className={styles.message}>
          { deleteMessage }
        </div>
      </MessageModal>
    </>
  );
}

ReviewGuides.propTypes = propTypes;
ReviewGuides.defaultProps = defaultProps;

export default ReviewGuides;
