import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import MarkdownIt from 'markdown-it';
import styles from './Markdown.module.css';

const propTypes = {
  input: PropTypes.string,
};

const defaultProps = {
  input: undefined,
};

function Markdown({ input }) {
  const html = useMemo(() => {
    if (input) {
      // @note: Since the markdown-it html option is not set,
      // html elements can't be passed though and the output is safe
      return MarkdownIt().render(input);
    }
    return '';
  }, [input]);

  if (!input) return null;

  return (
    <div className={styles.markdown}>
      { /* eslint-disable-next-line react/no-danger */ }
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
}

Markdown.propTypes = propTypes;
Markdown.defaultProps = defaultProps;

export default Markdown;
