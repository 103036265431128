import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  CloseButton,
  Icon,
} from '@makeably/creativex-design-system';
import styles from './ReviewGuideAsset.module.css';

export const assetProps = PropTypes.shape({
  creativeType: PropTypes.oneOf(['image', 'video']).isRequired,
  url: PropTypes.string.isRequired,
});

const propTypes = {
  asset: assetProps.isRequired,
  showLarge: PropTypes.bool,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
};

const defaultProps = {
  onClick: undefined,
  onRemove: undefined,
  showLarge: false,
};

function renderContent({ creativeType, url }, showLarge) {
  if (creativeType === 'video') {
    return (
      <>
        <video
          alt="Review Guide Asset"
          autoPlay={showLarge}
          controls={showLarge}
          src={url}
        />
        <div className={styles.play}>
          <Icon color="white" name="play" />
        </div>
      </>
    );
  }

  return (
    <img
      alt="Review Guide Asset"
      src={url}
    />
  );
}

function renderRemove(onRemove) {
  if (!onRemove) return null;

  return (
    <div className={styles.remove}>
      <CloseButton onClick={onRemove} />
    </div>
  );
}

function ReviewGuideAsset({
  asset,
  showLarge,
  onClick,
  onRemove,
}) {
  const classes = classNames(
    styles.asset,
    {
      [styles.clickable]: onClick,
      [styles.small]: !showLarge,
      [styles.large]: showLarge,
    },
  );

  return (
    <div className={styles.wrap}>
      <button
        key={asset.url}
        className={classes}
        type="button"
        onClick={onClick}
      >
        { renderContent(asset, showLarge) }
      </button>
      { renderRemove(onRemove) }
    </div>
  );
}

ReviewGuideAsset.propTypes = propTypes;
ReviewGuideAsset.defaultProps = defaultProps;

export default ReviewGuideAsset;
