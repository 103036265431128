import React, {
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Card,
} from '@makeably/creativex-design-system';
import ReviewGuide, { guideProps } from 'components/internal/ReviewGuide';
import ReviewGuideEditor from 'components/internal/ReviewGuideEditor';
import ActionButton from 'components/molecules/ActionButton';
import {
  addErrorToast,
  addToast,
} from 'components/organisms/Toasts';
import { patch } from 'utilities/requests';
import {
  internalReviewGuidePath,
  internalReviewGuidesPath,
  internalReviewGuideSubscriptionsPath,
} from 'utilities/routes';
import { toUnixFileEndings } from 'utilities/string';
import styles from './EditReviewGuide.module.css';

const propTypes = {
  initialGuide: guideProps.isRequired,
};

function areSameAssets(assets, lasts) {
  if (assets.length !== lasts.length) {
    return false;
  }

  return assets.every(({ creativeType, url }) => (
    lasts.find((last) => last.creativeType === creativeType && last.url === url)
  ));
}

function isSame(guide, last) {
  if (guide.title !== last.title) {
    return false;
  }

  if (toUnixFileEndings(guide.helperText) !== toUnixFileEndings(last.helperText)) {
    return false;
  }

  return areSameAssets(guide.assets, last.assets);
}

function EditReviewGuide({ initialGuide }) {
  const [guide, setGuide] = useState(initialGuide);
  const [isSaving, setIsSaving] = useState(false);
  const isComplete = Boolean(guide.title && guide.helperText);
  const hasChanges = useMemo(() => !isSame(guide, initialGuide), [guide, initialGuide]);

  const handleClose = () => {
    window.location.replace(internalReviewGuidesPath());
  };

  const handleUpdate = async () => {
    setIsSaving(true);

    const assets = guide.assets.map(({
      creativeType,
      id,
      url,
    }) => ({
      creative_type: creativeType,
      id,
      url,
    }));

    const formData = new FormData();
    formData.append('assets', JSON.stringify(assets));
    formData.append('helper_text', guide.helperText);
    formData.append('title', guide.title);

    const { isError } = await patch(internalReviewGuidePath(guide.id), formData);

    if (isError) {
      addErrorToast('Could not save review guide. Please try again later');
      setIsSaving(false);
    } else {
      addToast('Updated review guide');
      handleClose();
    }
  };

  return (
    <div className="u-flexColumn u-gap-24">
      <Card>
        <div className={styles.subscriptions}>
          <Button
            label="Subscriptions"
            url={internalReviewGuideSubscriptionsPath(guide.id)}
            variant="tertiary"
          />
        </div>
        <ReviewGuideEditor
          guide={guide}
          onChange={setGuide}
        />
        <div className={styles.controls}>
          <div className="u-flexRow u-gap-8">
            <Button
              label="Cancel"
              variant="secondary"
              onClick={handleClose}
            />
            <ActionButton
              active={isSaving}
              disabled={!(isComplete && hasChanges)}
              label="Update"
              onClick={handleUpdate}
            />
          </div>
        </div>
      </Card>
      <Card>
        <div className={styles.preview}>
          <div className="t-caption-1">Preview</div>
        </div>
        <ReviewGuide
          guide={guide}
          initialIsOpen
        />
      </Card>
    </div>
  );
}

EditReviewGuide.propTypes = propTypes;

export default EditReviewGuide;
