import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Checkbox,
  Search,
} from '@makeably/creativex-design-system';
import {
  emptyState,
  filterProps,
  getNullableDimension,
} from 'components/internal/shared';
import ActionButton from 'components/molecules/ActionButton';
import FilterTags from 'components/molecules/FilterTags';
import ItemsFilter from 'components/molecules/ItemsFilter';
import ItemsTable from 'components/molecules/ItemsTable';
import {
  addToast,
  addErrorToast,
} from 'components/organisms/Toasts';
import { saveFilterQuery } from 'utilities/filtering';
import { getItemSortBy } from 'utilities/item';
import { setItemsElement } from 'utilities/itemElement';
import {
  filterItems,
  getValueOptions,
} from 'utilities/itemFilter';
import { searchItems } from 'utilities/itemSearch';
import { toggleProperty } from 'utilities/object';
import { post } from 'utilities/requests';
import { internalReviewGuideSubscriptionsPath } from 'utilities/routes';
import {
  getPage,
  getParams,
  setParam,
} from 'utilities/url';
import styles from './NewReviewGuideSubscription.module.css';

const definitionProps = PropTypes.shape({
  category: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  guidelineId: PropTypes.number.isRequired,
  guidelineName: PropTypes.string.isRequired,
  definitionId: PropTypes.number,
  definitionName: PropTypes.string,
});

const subscriptionProps = PropTypes.shape({
  guidelineId: PropTypes.number.isRequired,
  definitionId: PropTypes.number,
});

const propTypes = {
  definitions: PropTypes.arrayOf(definitionProps).isRequired,
  guidelines: PropTypes.arrayOf(definitionProps).isRequired,
  reviewGuideId: PropTypes.string.isRequired,
  subscriptions: PropTypes.arrayOf(subscriptionProps).isRequired,
  initialFilterSelections: filterProps,
};

const defaultProps = {
  initialFilterSelections: {},
};

const filterDimensions = [
  {
    label: 'Company',
    value: 'company',
  },
  {
    label: 'Guideline Name',
    value: 'guidelineName',
  },
  {
    label: 'Type',
    value: 'category',
  },
];

const headers = [
  {
    key: 'selected',
    label: 'Add',
  },
  {
    key: 'company',
    label: 'Company',
  },
  {
    key: 'category',
    label: 'Type',
  },
  {
    key: 'guidelineId',
    label: 'Guideline\nID',
  },
  {
    key: 'guidelineName',
    label: 'Guideline\nName',
  },
  {
    key: 'definitionId',
    label: 'Definition\nID',
  },
  {
    key: 'definitionName',
    label: 'Definition\nName',
  },
];

function getCombinedId({ guidelineId, definitionId }) {
  return `${guidelineId}::${definitionId}`;
}

function parseId(str) {
  const value = parseInt(str);

  return Number.isNaN(value) ? null : value;
}

function parseCombinedId(id) {
  const parts = id.split('::');

  return {
    guideline_id: parseId(parts[0]),
    guideline_detail_id: parseId(parts[1]),
  };
}

function getItems(records, subscriptions, selected, handleSelect) {
  const existingById = subscriptions.reduce((obj, subscription) => ({
    ...obj,
    [getCombinedId(subscription)]: true,
  }), {});

  return records.reduce((arr, record) => {
    const id = getCombinedId(record);

    if (existingById[id]) {
      return arr;
    }

    const {
      category,
      company,
      definitionId,
      definitionName,
      guidelineId,
      guidelineName,
    } = record;
    const isSelected = Boolean(selected[id]);

    return [
      ...arr,
      {
        category: { value: category },
        company: { value: company },
        definitionId: getNullableDimension(definitionId),
        definitionName: getNullableDimension(definitionName),
        guidelineId: { value: guidelineId },
        guidelineName: { value: guidelineName },
        id: { value: id },
        selected: {
          element: (
            <Checkbox
              checked={isSelected}
              onChange={() => handleSelect(id)}
            />
          ),
          value: isSelected,
        },
      },
    ];
  }, []);
}

function NewReviewGuideSubscription({
  definitions,
  guidelines,
  initialFilterSelections,
  reviewGuideId,
  subscriptions,
}) {
  const params = getParams(window);
  const [selected, setSelected] = useState({});
  const [items, setItems] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [filterSelections, setFilterSelections] = useState(initialFilterSelections);
  const [filteredItems, setFilteredItems] = useState([]);
  const [search, setSearch] = useState('');
  const [searchedItems, setSearchedItems] = useState([]);
  const [sort, setSort] = useState({
    key: 'selected',
    asc: false,
  });
  const [sortedItems, setSortedItems] = useState([]);
  const [page, setPage] = useState(getPage(params));
  const [isAdding, setIsAdding] = useState(false);

  const handleSelect = (id) => {
    setSelected((last) => toggleProperty(last, id, true));
  };

  useEffect(() => {
    const records = [...guidelines, ...definitions];
    const allItems = getItems(records, subscriptions, selected, handleSelect);
    const options = getValueOptions(filterDimensions, allItems);

    setItems(allItems);
    setFilterOptions(options);
  }, [definitions, guidelines, subscriptions, selected]);

  useEffect(() => {
    setFilteredItems(filterItems(items, filterSelections));
  }, [items, filterSelections]);

  useEffect(() => {
    const keys = ['definitionId', 'definitionName', 'guidelineId', 'guidelineName'];
    const found = searchItems(filteredItems, search, keys);
    setSearchedItems(setItemsElement(found));
  }, [filteredItems, search]);

  useEffect(() => {
    const byKeyDir = getItemSortBy(sort.key, sort.asc);
    setSortedItems(searchedItems.slice().sort(byKeyDir));
  }, [searchedItems, sort]);

  const handleClose = () => {
    window.location.replace(internalReviewGuideSubscriptionsPath(reviewGuideId));
  };

  const handleAdd = async () => {
    setIsAdding(true);
    const subscriptionIds = Object.keys(selected).map((id) => parseCombinedId(id));

    const formData = new FormData();
    formData.append('subscriptions', JSON.stringify(subscriptionIds));

    const { isError } = await post(internalReviewGuideSubscriptionsPath(reviewGuideId), formData);

    if (isError) {
      addErrorToast('Could not add subscriptions. Please try again later');
      setIsAdding(false);
    } else {
      addToast(`Added ${subscriptionIds.length} subscriptions`);
      handleClose();
    }
  };

  const handleFilterSelect = async (value) => {
    setPage(1);
    setFilterSelections(value);

    if (Object.keys(value).length > 0) {
      const uuid = await saveFilterQuery(value);
      setParam('filter_uuid', uuid, params, window);
    } else {
      setParam('filter_uuid', '', params, window);
    }
  };

  return (
    <Card className={styles.main}>
      <div className={styles.controls}>
        <Search
          placeholder="Guideline, Definition, or IDs"
          size="large"
          value={search}
          onChange={setSearch}
        />
        <div className={styles.filter}>
          <ItemsFilter
            dimensions={filterDimensions}
            isOpen={filterOpen}
            options={filterOptions}
            selections={filterSelections}
            onClose={() => setFilterOpen(false)}
            onOpen={() => setFilterOpen(true)}
            onSelect={handleFilterSelect}
          />
          <FilterTags
            dimensions={filterDimensions}
            selections={filterSelections}
            onClick={() => setFilterOpen(true)}
            onRemove={handleFilterSelect}
          />
        </div>
      </div>
      <ItemsTable
        className="x-scroll-shadows"
        emptyTableContent={emptyState}
        headers={headers}
        items={sortedItems}
        page={page}
        sort={sort}
        onPageChange={setPage}
        onSortChange={setSort}
      />
      <div className={styles.buttons}>
        <div className="u-flexRow u-gap-8">
          <Button
            label="Cancel"
            variant="secondary"
            onClick={handleClose}
          />
          <ActionButton
            active={isAdding}
            disabled={Object.values(selected).length === 0}
            label="Add"
            onClick={handleAdd}
          />
        </div>
      </div>
    </Card>
  );
}

NewReviewGuideSubscription.propTypes = propTypes;
NewReviewGuideSubscription.defaultProps = defaultProps;

export default NewReviewGuideSubscription;
