import React, { useState } from 'react';
import {
  Button,
  Card,
} from '@makeably/creativex-design-system';
import ReviewGuide from 'components/internal/ReviewGuide';
import ReviewGuideEditor from 'components/internal/ReviewGuideEditor';
import ActionButton from 'components/molecules/ActionButton';
import {
  addErrorToast,
  addToast,
} from 'components/organisms/Toasts';
import { post } from 'utilities/requests';
import { internalReviewGuidesPath } from 'utilities/routes';
import styles from './NewReviewGuide.module.css';

function NewReviewGuide() {
  const [guide, setGuide] = useState({ assets: [] });
  const [isSaving, setIsSaving] = useState(false);
  const isComplete = guide.title && guide.helperText;

  const handleClose = () => {
    window.location.replace(internalReviewGuidesPath());
  };

  const handleSave = async () => {
    setIsSaving(true);
    const assets = guide.assets.map(({ creativeType, url }) => ({
      creative_type: creativeType,
      url,
    }));

    const formData = new FormData();
    formData.append('assets', JSON.stringify(assets));
    formData.append('helper_text', guide.helperText);
    formData.append('title', guide.title);

    const { isError } = await post(internalReviewGuidesPath(), formData);

    if (isError) {
      addErrorToast('Could not save review guide. Please try again later');
      setIsSaving(false);
    } else {
      addToast('Saved review guide');
      handleClose();
    }
  };

  return (
    <div className="u-flexColumn u-gap-24">
      <Card>
        <ReviewGuideEditor
          guide={guide}
          onChange={setGuide}
        />
        <div className={styles.controls}>
          <div className="u-flexRow u-gap-8">
            <Button
              label="Cancel"
              variant="secondary"
              onClick={handleClose}
            />
            <ActionButton
              active={isSaving}
              disabled={!isComplete}
              label="Save"
              onClick={handleSave}
            />
          </div>
        </div>
      </Card>
      <Card>
        <div className={styles.preview}>
          <div className="t-caption-1">Preview</div>
        </div>
        <ReviewGuide
          guide={guide}
          initialIsOpen
        />
      </Card>
    </div>
  );
}

export default NewReviewGuide;
