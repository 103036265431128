import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  CloseButton,
  Icon,
} from '@makeably/creativex-design-system';
import Markdown from 'components/atoms/Markdown';
import ReviewGuideAsset, { assetProps } from 'components/internal/ReviewGuideAsset';
import styles from './ReviewGuide.module.css';

export const guideProps = PropTypes.shape({
  assets: PropTypes.arrayOf(assetProps),
  helperText: PropTypes.string,
  id: PropTypes.number,
  title: PropTypes.string,
});

const propTypes = {
  guide: guideProps.isRequired,
  initialIsOpen: PropTypes.bool,
};

const defaultProps = {
  initialIsOpen: false,
};

function ReviewGuide({ guide, initialIsOpen }) {
  const {
    assets = [],
    helperText = '',
    title = '',
  } = guide;
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const [largeUrl, setLargeUrl] = useState(null);
  const large = assets.find(({ url }) => url === largeUrl);

  const toggleLargeUrl = (url) => {
    setLargeUrl((last) => (url !== last ? url : null));
  };

  return (
    <div className={styles.main}>
      <div className={styles.title}>
        <h5>{ title }</h5>
        <button
          className={styles.collapse}
          type="button"
          onClick={() => setIsOpen((last) => !last)}
        >
          <Icon name={isOpen ? 'chevronUp' : 'chevronDown'} />
        </button>
      </div>
      { isOpen && (
        <>
          <div>
            <Markdown input={helperText} />
          </div>
          <div className={styles.assets}>
            { guide.assets.map((asset) => (
              <ReviewGuideAsset
                key={asset.url}
                asset={asset}
                onClick={() => toggleLargeUrl(asset.url)}
              />
            )) }
          </div>
          { large && (
            <div className={styles.large}>
              <ReviewGuideAsset
                key={large.url}
                asset={large}
                showLarge
              />
              <CloseButton onClick={() => setLargeUrl(null)} />
            </div>
          ) }
        </>
      ) }
    </div>
  );
}

ReviewGuide.propTypes = propTypes;
ReviewGuide.defaultProps = defaultProps;

export default ReviewGuide;
