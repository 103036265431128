import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextArea,
  TextInput,
} from '@makeably/creativex-design-system';
import ReviewGuideAssets from 'components/internal/ReviewGuideAssets';
import styles from './ReviewGuideEditor.module.css';

const propTypes = {
  guide: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
};

const markdownText = `* First Thing
* Second Thing
---

##### Other steps to follow
1. Verify the logo
1. Verify the text
*Remember*: Do that **important thing** and the other things.
---

For more examples, see this [markdown reference](https://commonmark.org/help/).
`;

function ReviewGuideEditor({ guide, onChange }) {
  const handleChange = (key, value) => {
    onChange({
      ...guide,
      [key]: value,
    });
  };

  return (
    <div className={`x-scroll-shadows ${styles.main}`}>
      <div className={styles.text}>
        <TextInput
          label="Title"
          size="large"
          value={guide.title ?? ''}
          onChange={(value) => handleChange('title', value)}
        />
        <div>
          <TextArea
            label="Helper Text (Markdown)"
            size="large"
            value={guide.helperText ?? ''}
            onChange={(value) => handleChange('helperText', value)}
          />
          <div className={styles.buttons}>
            <Button
              label="Set Example Markdown"
              variant="tertiary"
              onClick={() => handleChange('helperText', markdownText)}
            />
            <Button
              label="Clear"
              variant="secondary"
              onClick={() => handleChange('helperText', '')}
            />
          </div>
        </div>
      </div>
      <div className={styles.assets}>
        <div className={`t-caption-1 ${styles.label}`}>
          Assets
        </div>
        <ReviewGuideAssets
          assets={guide.assets}
          onChange={(value) => handleChange('assets', value)}
        />
      </div>
    </div>
  );
}

ReviewGuideEditor.propTypes = propTypes;

export default ReviewGuideEditor;
